import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2997050974/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FormProps } from '../../components/FormProps';
export const name = 'WebhookForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  name,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{name}</h2>
The webhook form is used for creating and editing webhooks within the system.
    <FormProps fields={[{
      name: 'name',
      type: 'text'
    }, {
      name: 'type',
      type: 'select'
    }, {
      name: 'event',
      type: 'select'
    }, {
      name: 'filter',
      type: 'code'
    }, {
      name: 'url',
      type: 'text'
    }]} formOptions={[{
      name: 'kappSlug',
      type: 'string',
      description: 'Should be provided when interacting with webhooks within a kapp.'
    }, {
      name: 'name',
      type: 'string',
      description: 'When provided the form will be populated by retrieving the corresponding webhook and will update that webhook when submitted. If not provided the form submission will create a new webhook.'
    }]} dataSources={[{
      name: 'space',
      type: 'Space'
    }, {
      name: 'kapp',
      type: 'Kapp'
    }, {
      name: 'webhook',
      type: 'Webhook'
    }, {
      name: 'events',
      type: 'Event[]'
    }]} mdxType="FormProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      